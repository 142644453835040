'use strict';

$(document).ready ->
	if !$.cookie('language')?
		userLang = navigator.language || navigator.userLanguage
		if userLang != 'fr' && userLang != 'en'
			userLang = 'en'
		$.cookie('language', userLang, { path: '/' })
		$selector = $('.header-toolbar-language em[data-language=' + userLang + ']')
		if $selector.size() && !$selector.is('.active')
			$selector.addClass('blink')


	###
	# Menu
	###
	$('.navbar li a').click (e) ->
		$('.navbar > li').removeClass('active')
		$(this).parent().addClass('active');
		$(this).parents('li').parents('li').addClass('active');

		if (window.innerWidth < 768 || e.button != 0)
			if $(this).data('toggle')
				if window.innerWidth < 768
					$(this).next('.dropdown-menu').toggle()
				else
					$(this).next('.dropdown-menu').slideToggle()
				return false;
			return true

		if $(this).parent().hasClass('dropdown')
			#e.preventDefault();
			e.stopPropagation();
			return bodyClickA e, $(this)

		if !$(this).attr('target')?
			e.preventDefault()
	###
	# / Menu
	###


	$(window).resize(->
		# Multi-devices backgrounds
		$('[data-background-lg][data-background-md][data-background-sm][data-background-xs]').each ->
			if window.innerWidth < 768
				$(this).css('background-image', 'url(' + $(this).data('background-xs') + ')')
			else if window.innerWidth < 992
				$(this).css('background-image', 'url(' + $(this).data('background-sm') + ')')
			else if window.innerWidth < 1200
				$(this).css('background-image', 'url(' + $(this).data('background-md') + ')')
			else
				$(this).css('background-image', 'url(' + $(this).data('background-lg') + ')')

		# Resize homepage carousel
		$('.carousel, .carousel .item, .carousel .item .container').css('height', $('.carousel').width() / 3)
	).resize()

	###
	# Links click
	###
	$('body').on 'click', 'a.link-popup', (e) ->
		e.preventDefault()
		e.stopPropagation()
		window.open($(this).attr('href'), '', 'height=300, width=600, top=' + (($(window).height() / 2) - 150) + ', left=' + (($(window).width() / 2) - 300) + ', toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');

	bodyClickA = (e, _this) ->
		if _this != undefined
			$this = _this
		else
			$this = $(this)

		if (window.innerWidth < 768 || (e? && e.button? && e.button != 0) || $this.attr('target')? || $this.data('noajax')?)
			return true	
		if $this.hasClass("link-popup")
			return false

		href = $this.attr('href')
		if href? && href[0] == '/'
			if e? then e.preventDefault()
			if ga?
				infoGoogle = 
					hitType : "pageview";
					page : href
				ga('send', infoGoogle);
				#_gaq.push(['_trackPageview', href])
			if window.history.pushState?
				window.history.pushState(null, null, href);
			else
				window.location.href = window.location.href + '#' + href

			$('nav ul.navbar-nav > li').removeClass 'active'
			$this.parents('li').addClass 'active'
			
			#$.get location.protocol + '//' + location.hostname + href + (if href.indexOf('?') >= 0 then '&' else '?') + 'ajax=1', (data) ->
			$.get location.protocol + '//' + location.hostname + href, (data) ->
				$("html, body").animate({scrollTop:0}, '500', 'swing', ->
					$('#content').fadeOut(500, ->
						
						# Reinit Angular
						if $('[ng-app]').size() && typeof $('[ng-app]').injector != 'undefined'
							injector = $('[ng-app]').injector()
							$compile = injector.get('$compile')
							linkFn = $compile(data)
							$rootScope = injector.get('$rootScope')
							elem = linkFn($rootScope)
							$('#content').html(elem)
							$rootScope.$digest()
						else
							if ($(data).find('#content'))
								# Update content
								$('#content').html($(data).find('#content').html())
								# Update Language Switcher
								$('.header-toolbar-language').html($(data).find('.header-toolbar-language').html())
							else
								$('#content').html(data)

						$('#content').fadeIn(500)
						setTimeout(reloadAjax, 10)
						document.title = window.title;
					)
				)

	$('body').on 'click', 'a', bodyClickA

	window.addEventListener 'popstate', (->
		window.location.href = window.location
		return false
	), false

	$window = $(window)
	$window.on 'scroll resize', ->
		$animation_elements = $('.animation-element')
		window_height = $window.height()
		window_top_position = $window.scrollTop()
		window_bottom_position = window_top_position + window_height
		$.each $animation_elements, ->
			$element = $(this)
			element_height = $element.outerHeight()
			element_top_position = $element.offset().top
			element_bottom_position = element_top_position + element_height
			#check to see if this current container is within viewport
			if element_bottom_position >= window_top_position and element_top_position <= window_bottom_position
				$element.addClass 'in-view'
			else
				$element.removeClass 'in-view'
			return
		return
	$window.trigger 'scroll'

	reloadAjax = ->
		#$('.datepicker').datepicker($.datepicker.regional["fr"]);

		###
		# Menu
		###
		$(window).trigger 'resize'
		sideslider = $('[data-toggle=collapse-side]')
		sidesliderTarget = sideslider.attr('data-target')
		sidesliderTarget2 = sideslider.attr('data-target-2')
		sideslider.click (e) ->
			$(sidesliderTarget).toggleClass 'in'
			$(sidesliderTarget2).toggleClass 'out'
			e.preventDefault()
			e.stopPropagation()
			return

		$('.side-collapse-container').click (e)->
			if $(e.target).parents('nav').size()
				return true

			if $(this).is('.out')
				e.preventDefault()
				e.stopPropagation()
				sideslider.click()

		$('.side-collapse').click (e)->
			if $(this).not('.in')
				;#e.stopPropagation()
		###
		# /Menu
		###

	reloadAjax()
	###
	# / Fade click effect
	###

	###
	# Homepage
	###
	$('body').on 'submit', '#newsletter-form', (e) ->
		$form = $(this)
		e.preventDefault()
		
		$.post $form.attr('action'), {
			email: $form.find('[name=email]').val()
			language: $form.find('[name=language]').val()
		}, ((data) ->
			if data.status == 'error'
				alert data.message
			else
				$form.find('[type=submit]').fadeOut 700, ->
					$form.find('span.btn').fadeTo 700, 0.65
		), 'json'
	###
	# /Homepage
	###

numberFormat = (number) ->
	return (number*1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',');